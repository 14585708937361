export const mockPosts = {
	'AnA2-P15c': {
		name: 'New page',
		path: '/howtomakenachos',
		blocks: [
			'AnA2-P15c-header',
			'AnA2-P15c-section',
		],
		type: 'blog',
		isDraft: false,
		coverImageOrigin: 'unsplash',
		coverImagePath: 'photo-1582169296194-e4d644c48063?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjcyNTgzfQ',
		coverImageAlt: 'selective focus photography of white USB port',
		date: '2020-06-01',
		categories: [],
		minutesToRead: '4',
		meta: {
			title: 'How to make the perfect nachos',
			description: 'Nachos are tasty. But they can be made even better.',
			ogImageOrigin: 'unsplash',
			ogImagePath: 'photo-1582169296194-e4d644c48063?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjcyNTgzfQ',
			ogImageAlt: 'selective focus photography of white USB port',
		},
	},
	xYeRqh3F4: {
		name: 'New page',
		path: '/pullingallnighters',
		blocks: [
			'xYeRqh3F4-header',
			'xYeRqh3F4-section',
		],
		type: 'blog',
		isDraft: false,
		coverImageOrigin: 'unsplash',
		coverImagePath: 'photo-1536746803623-cef87080bfc8?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjcyNTgzfQ',
		coverImageAlt: 'four brown gift boxes on white surface',
		date: '2020-06-01',
		categories: [],
		minutesToRead: '1',
		meta: {
			title: 'Pulling all nighters',
			description: 'Every developer has done this. And will do it again and again and again...',
			ogImageOrigin: 'unsplash',
			ogImagePath: 'photo-1536746803623-cef87080bfc8?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjcyNTgzfQ',
			ogImageAlt: 'four brown gift boxes on white surface',
		},
	},
};
