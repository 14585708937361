<template>
	<!-- eslint-disable max-len -->
	<button
		class="delete-button"
		@click="$emit('handle-button-click')"
	>
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2 4H3.33333H14"
				stroke="#767676"
				stroke-width="0.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M5.3335 4.00004V2.66671C5.3335 2.31309 5.47397 1.97395 5.72402 1.7239C5.97407 1.47385 6.31321 1.33337 6.66683 1.33337H9.3335C9.68712 1.33337 10.0263 1.47385 10.2763 1.7239C10.5264 1.97395 10.6668 2.31309 10.6668 2.66671V4.00004M12.6668 4.00004V13.3334C12.6668 13.687 12.5264 14.0261 12.2763 14.2762C12.0263 14.5262 11.6871 14.6667 11.3335 14.6667H4.66683C4.31321 14.6667 3.97407 14.5262 3.72402 14.2762C3.47397 14.0261 3.3335 13.687 3.3335 13.3334V4.00004H12.6668Z"
				stroke="#767676"
				stroke-width="0.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M6.6665 7.33337V11.3334"
				stroke="#767676"
				stroke-width="0.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M9.3335 7.33337V11.3334"
				stroke="#767676"
				stroke-width="0.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	</button>
</template>

<style lang="scss" scoped>
.delete-button {
	cursor: pointer;
}
</style>
